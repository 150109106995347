import DefaultBackground from '../background/default-background'
import Footer from '../core/footer'
import GlobalStyles from '../core/global-styles'
import { graphql } from 'gatsby'
import Header from '../core/header'
import mistergreenConfig from '../../../mistergreen-config'
import { MisterGreenConfig } from '../../utilities/general/types'
import PageWrapper from '../ui/page-wrapper'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import SEO from '../core/seo'
import { ShoppingCartLabels } from '../core/shopping-cart'
import styled from 'styled-components'

const { locales }: MisterGreenConfig = mistergreenConfig

interface LegalDocumentData {
    mistergreen: {
        getLegalDocumentContent: {
            id: string
            title: string
            body: string
            summary: string
        } | null
    }
    craftSeoGlobalSet: {
        id: string
        defaultAuthor: string
    }
    craftShoppingCartGlobalSet: ShoppingCartLabels
}

interface LegalDocumentPageProps {
    data: LegalDocumentData
    pageContext: {
        documentId: string
        environment: string
        localeSiteIds: number[]
    }
}

const LegalDocumentPage = ({ data, pageContext }: LegalDocumentPageProps): JSX.Element => {
    const { defaultAuthor } = data.craftSeoGlobalSet

    const localeLinks = locales.map((locale, index) => {
        return {
            id: index,
            isCurrent: '',
            language: locale.language,
            languagePrettyName: locale.languagePrettyName,
            slug: `/${locale.language}/`,
        }
    })

    return (
        <>
            <SEO
                author={defaultAuthor}
                title={data.mistergreen.getLegalDocumentContent?.title ?? ''}
                description={data.mistergreen.getLegalDocumentContent?.summary ?? ''}
                environment={pageContext.environment}
            />
            <GlobalStyles />
            <PageWrapper>
                <Header
                    localeLinks={localeLinks}
                    isFullNavBar={false}
                    isNavBarSeeThrough={false}
                    renderShoppingCart={false}
                    shoppingCartLabels={data.craftShoppingCartGlobalSet}
                />
                <main>
                    <DefaultBackground>
                        <Container>
                            <h1>{data.mistergreen.getLegalDocumentContent?.title}</h1>
                            <ReactMarkdown source={data.mistergreen.getLegalDocumentContent?.body} />
                        </Container>
                    </DefaultBackground>
                </main>
                <Footer localeLinks={localeLinks} contactDetails={[]} />
            </PageWrapper>
        </>
    )
}

export default LegalDocumentPage

const Container = styled.article`
    max-width: var(--max-single-element-width);
    padding: 0 var(--gutter-width);
    margin: 0 auto;
`

export const query = graphql`
    query getLegalDocumentPage($documentId: ID!, $localeSiteIds: [Int]) {
        mistergreen {
            getLegalDocumentContent(legalDocumentId: $documentId) {
                id
                title
                body
                summary
            }
        }
        craftSeoGlobalSet(siteId: { in: $localeSiteIds }) {
            id
            defaultAuthor
        }
        craftShoppingCartGlobalSet(siteId: { in: $localeSiteIds }) {
            shoppingCartMainHeading
            dealLinkText
            configurationStatusText
            offerStatusText
            contractStatusText
            priceSuffixExclVat
            priceSuffixInclVat
            shoppingCartLoggedOutHeading
            loggedOutText
            logInLinkText
            shoppingCartNoDealsYetHeading
            noDealsYetText
            noDealLinkTemplate
        }
    }
`
