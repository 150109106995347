import styled from 'styled-components'

const DefaultBackground = styled.div`
    margin: var(--vertical-spacing) 0 0;

    &:first-child {
        margin-top: var(--spacing-top-of-page);
    }
`

export default DefaultBackground
